@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
* {
  margin: 0 0;
  font-family: "Montserrat", sans-serif;
}
a {
  text-decoration: none;
  color: #000;
}
