:root {
  --green: #314726;
  --white: #e9e9ea;
}
.container {
  max-width: 1400px;
  margin: 0 auto;
}
.mobile-links {
  overflow: hidden;
  height: 0px !important;
  transition: height 0.3s ease-in-out;
}
.mobile-links.active {
  height: 260px !important;
}
.shadow-krc {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.shadow-krc-2 {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
